.my-form {
  background-image: url("../assets/map.svg");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px;
  min-height: 100vh;
}

#basic-addon1 {
  background-color: #f2f2f2;
}

.form-size {
  max-width: 1100px;
  margin: 0 auto;
}

.form-size:not(.form-size:last-child) {
  margin-bottom: 10px;
}

.button-size {
  width: 200px;
  height: 70px;
  margin-top: 10px;
  background-color: #603a15;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  vertical-align: middle;
}
.button-size:hover {
  background-color: transparent;
  border: 2px solid #603a15;
  color: #603a15;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
  .my-form {
    padding: 20px;
  }
  .button-size {
    width: 150px;
    height: 70px;
  }
}

@media (max-width: 576px) {
  .my-form {
    padding: 10px;
  }
  .button-size {
    width: 150px;
    height: 70px;
  }
}
