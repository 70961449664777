.hero-section {
  position: relative;
  background-image: url("../assets/bghero.jpg");
  background-size: cover;
  background-position: center;
  height: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hero-content {
  text-align: center;
  color: white;
  z-index: 2;
}

.hero-title {
  font-size: 48px;
  margin-bottom: 38px;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 42px;
}

.hero-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media (max-width: 768px) {
  .hero-section {
    height: 70vh;
  }

  .hero-title {
    font-size: 32px;
    margin-bottom: 16px;
  }

  .hero-description {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 60vh;
  }

  .hero-title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .hero-description {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.jelajahi-btn {
  background-color: white;
  color: #000;
  border: none;
  border-radius: 20px;
  width: 149px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}

.jelajahi-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 2px solid #fff;
}

.video-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  margin-left: 28px;
}

.video-button .fa-play {
  margin-right: 8px;
}

.video-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 2px solid #fff;
}
