.article-image {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
  width: 820px;
  height: 550px;
}
.article-card {
  position: relative;
  overflow: hidden;
  width: 85%;
}

.article-card img {
  transition: transform 0.2s;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.article-card:hover img {
  transform: scale(1.1);
  border-radius: 10px;
}

.article-card h6 {
  transition: color 0.2s;
}

.article-card:hover h6 {
  color: blue;
}

@media (max-width: 991.98px) {
  .article-image {
    width: 340px;
    height: 320px;
  }
  .article-card h6 {
    font-size: 10px;
  }
}
