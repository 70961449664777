.btn-custom-size {
  border-radius: 10px;
  font-size: 0.7rem;
  padding: 10px 20px;
}

.date-semi-bold {
  font-weight: 600;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  margin: auto;
}
