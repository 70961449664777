.title {
  color: black;
  font-weight: bold;
}

.description {
  color: #603a15;
  margin-bottom: 50px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}

.card-content-white {
  color: white;
}

.card-paket {
  margin-bottom: 30px;
  width: 100%;
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-text {
  max-width: 60%;
}

.price {
  font-size: 32px;
  font-weight: semibold;
}

.btn-custom {
  width: 184px;
  height: 46px;
  background-color: #f86d0c;
  color: white;
  border: none;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-custom:last-child {
  margin-right: 0;
}

.btn-custom-2 {
  background-color: #42b549;
}

.btn-custom-1:hover,
.btn-custom-2:hover {
  background-color: white;
  border: 2px solid;
}

.btn-custom-1:hover .iconify {
  color: #f86d0c;
}

.btn-custom-1:hover {
  color: #f86d0c;
  border-color: #f86d0c;
}

.btn-custom-2:hover {
  color: #0cf840;
  border-color: #0cf840;
}

@media (min-width: 1200px) {
  .card-paket {
    width: 1120px;
    height: 348px;
  }
}

@media (max-width: 1199.98px) {
  .card-paket {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 991.98px) {
  .price {
    font-size: 24px;
  }
  .btn-custom {
    width: 140px;
    height: 40px;
  }
}
