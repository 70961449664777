.map-container {
  position: relative;
  overflow: hidden;
  padding-top: 30%;
  height: 0;
}

.map-iframe {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 90%;
  border-radius: 10px;
  border: none;
}

@media (min-width: 768px) {
  .map-container {
    width: 50%;
    margin: 0 auto;
  }
}
.image-row {
  display: flex;
  align-items: center;
  justify-content: left;
}


.image-row img {
  height: 30px; 
  width: auto;
  object-fit: contain;
  margin-left: 5px;
  margin-right: 5px;
}
