.btn-katalog {
  background-color: transparent;
  width: 181px;
  height: 60px;
  font-weight: bold;
  border: 2px solid #603a15;
  color: #603a15;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.btn-katalog:hover {
  background-color: #603a15;
  color: #fff;
}

.card-body-katalog {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.catalog-card {
  background-color: #d0d0d0;
}
