.transparent-navbar {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
}
.navbar-toggler {
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  background: no-repeat center center;
  background-size: 100% 100%;
  position: relative;
}

.navbar-toggler-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 2px;
  background-color: #fff;
  box-shadow: 0 6px 0 0 #fff, 0 12px 0 0 #fff;
  transform: translate(-50%, -50%);
}

.custom-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.custom-toggler:focus,
.custom-toggler:hover {
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-toggler-icon {
  display: inline-block;
  width: 22px;
  height: 2px;
  background-color: #fff;
  box-shadow: 0 6px 0 0 #fff, 0 12px 0 0 #fff;
}

.nav-link-custom a {
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none;
}

.nav-dropdown-custom .dropdown-item a {
  color: rgba(255, 255, 255, 0.5) !important;
  text-decoration: none;
}
@media (max-width: 767px) {
  .navbar-toggler.custom-toggler[aria-expanded="true"] {
    background-color: grey;
  }

  .navbar-collapse {
    background-color: grey;
  }

  .navbar-nav .nav-link-custom {
    color: white;
  }

  .navbar-nav .nav-link-custom:hover {
    color: #eaeaea;
  }

  .nav-dropdown-custom .dropdown-menu {
    background-color: black;
  }

  .nav-dropdown-custom .dropdown-item {
    color: white;
  }

  .nav-dropdown-custom .dropdown-item:hover {
    background-color: #343a40;
  }
}
